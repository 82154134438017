import react from 'react';
import { Link } from 'wouter';
import { CellProps } from './types';

function Cell({ year, month, day, isToday }: CellProps): JSX.Element {
	return(
		<Link href={`/day/${year}/${month}/${day}`}>
			<a className={`cell${isToday ? ' today' : ''}`}>
				{isToday ? <span className='today-badge'></span> : null}
				<span className='inner'>
					{day}
				</span>
			</a>
		</Link>
	);
}

export default Cell;