import React from 'react';
import { FormProps } from './types';

function Form({ submitHandler, submitPlaceholder = 'Submit', children }: FormProps): JSX.Element {
	return(
		<form className='form' onSubmit={submitHandler}>
			{children}
			<input className='submit-form' type='submit' placeholder={submitPlaceholder} />
		</form>
	);
}

export default Form;