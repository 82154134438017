import React from 'react';
import { Redirect, useLocation } from 'wouter';
import { LayoutProps } from './types';
import useAppContext from 'context/AppContext';
import useFetch from 'hooks/useFetch';

function Layout({ children }: LayoutProps): JSX.Element {
	const [location, setLocation] = useLocation();
    // const [urlString, setUrlString] = useState<string | null>(null);
    // const { data, error } = useFetch<ObjectData[]>(urlString);
    // const objectEndpoint: string = 'https://api.restful-api.dev/objects';

    // interface ObjectAttributes {
    //     color: string;
    //     capacity: string;
    // }
    // interface ObjectData {
    //     name: string;
    //     id: number;
    //     data: null | ObjectAttributes;
    // }
    const { state: { currentUser }, actor: { setCurrentUser } } = useAppContext();

    if (!currentUser) {
        setLocation('/login');
    }

	return(
		<div className='app'>
            <header className='header'>
                <h1>Sundial</h1>
                {/*<p>
                    <button onClick={() => setUrlString(objectEndpoint)}>Fetch!</button>
                </p>*/}
                {currentUser ? <button className="logout" type="button" onClick={() => setCurrentUser(null)}>{`Logout, ${currentUser.username}`}</button> : null}
            </header>
            <main>
                <div className="container">
                	{children}
                </div>
            </main>
        </div>
	);
}

export default Layout;