import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/_root.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppContextLoader from './context/AppContextLoader';

const root = ReactDOM.createRoot(
 	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<AppContextLoader>
		    <App />
		</AppContextLoader>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
