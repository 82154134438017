import React from 'react';

export interface Activity {
	date: string;
	category: ActivityType;
	title: string;
	notes?: string;
	events: ActivityEvent[];
}

export enum ActivityType {
	STR = 'strength',
	DEX = 'dexterity',
	END = 'endurance',
	INT = 'intelligence'
}

export interface ActivityEvent {
	name: string;
	value: number;
	unit: string;
}

function Content(): JSX.Element {
	const userActivities: Activity[] = [{
		date: '2023-04-02',
		category: ActivityType.STR,
		title: 'Starting Strength',
		notes: 'Week 3 workout 1',
		events: [
			{
				name: 'deadlift1x5',
				value: 175,
				unit: 'lbs'
			},
			{
				name: 'squat3x5',
				value: 165,
				unit: 'lbs'
			},
			{
				name: 'dumbell press3x5',
				value: 60,
				unit: 'lbs'
			}
		]
	},
	{
		date: '2023-04-02',
		category: ActivityType.DEX,
		title: 'Flexibility Yoga',
		notes: '',
		events: [
			{
				name: 'https://www.youtube.com/watch?v=6JiIb8nUNJM',
				value: 1,
				unit: 'session'
			}
		]
	}]

	return(
		<div className='activity-list'>
			{userActivities.length === 0 ? 
				<p>{`Activities for today's date are listed here.`}</p>
				:
				userActivities.map(({ title, category, notes, events }: Activity) => {
					const notesExist = !!notes;
					return(
						<div className='activity-listing'>
							<h4>{`${title}`}</h4>
							<span className={`activity-type-badge ${category}`}>{`${category}`}</span>
							{notesExist ? 
								<Notes notes={notes} />
								: 
								null
							}
							<div className='activities'>
								{events.map(({ name, value, unit }) => {
									return(
										<div className='event'>
											<p>{`${name}`}</p>
											<p>{`${value} ${unit}`}</p>
										</div>
									);	
								})}
							</div>
						</div>
					);
				})
			}
		</div>
	);
}

interface NotesProps {
	notes: string;
}

function Notes({ notes }: NotesProps): JSX.Element {
	return(
		<div className='activity-notes'>
			<span className='activity-notes-label'>Notes</span>
			<div className='activity-notes-detail'>{`${notes}`}</div>
		</div> 
	);
}

export default Content;