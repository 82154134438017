import react from 'react';
import { Link, useLocation } from 'wouter';
import { DayProps } from './types';

import Content from './Content';
import { monthConstants } from 'utils/dates';

function Day({ day, month, year }: DayProps) {
	const [location, setLocation] = useLocation();

	if (!day || !month || !year) {
		setLocation('/');
	}

	const da = new Date();
	const today = da.getDate();
	const numberMonth = month ? Number(month) : 0;
	const numberDay = day ? Number(day) : 0;
	const isToday = today === numberDay;

	return(
		<div className="day">
			<div className="day-header">
				<Link href="/"><a className="back-link">Calendar</a></Link>
				<h3>
					{`${monthConstants[numberMonth]} ${day}, ${year}`}
				</h3>
				{isToday ? <><span className='today-badge'></span>{`       is today!`}</> : null}
			</div>
			<Content />
		</div>
	);
}

export default Day;