import react from 'react';
import Cell from './Cell';
import { CalendarProps } from './types';
import { getDaysInMonth, getFirstDayOfMonth, monthConstants } from 'utils/dates';

function Calendar(props: CalendarProps): JSX.Element {
	const da = new Date();
	const today = da.getDate();
	const newMonth = da.getMonth();
	const newYear = da.getFullYear();
	const numDaysInMonth = getDaysInMonth(newMonth, newYear);
	const firstDayOfMonth = getFirstDayOfMonth(newMonth, newYear);

	return(
		<>
	        <h2>{monthConstants[newMonth]}</h2>
			<div className="calendar">
	          {[...Array(numDaysInMonth + firstDayOfMonth)].map((u, i) => {
	          	const dateNumber = i - firstDayOfMonth + 1;
	            return i < firstDayOfMonth ? 
	            	<div className='cell'></div> 
	            	: 
	            	<Cell key={u} year={newYear} month={newMonth} day={dateNumber} isToday={dateNumber === today} />;
	          })}
	        </div>
	    </>
	);
}

export default Calendar;