import React, { createContext, useContext } from 'react';
import { AppState, AppActor } from './types';

export interface AppContextType {
	state: AppState;
	actor: AppActor;
}

export const AppContext = createContext<AppContextType | null>(null);

export default function useAppContext(): AppContextType {
	const appContext = useContext(AppContext);

	if (appContext === null) {
		throw new Error('No Provider component found');
	}

	return appContext;
}