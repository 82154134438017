import { AppContext } from './AppContext';
import useApp from './useApp';

export interface AppContextLoaderProps {
	children: React.ReactNode;
}

export default function AppContextLoader({ children }: AppContextLoaderProps): JSX.Element {
	const useAppInit = useApp();

	return(
		<AppContext.Provider value={useAppInit}>
			{children}
		</AppContext.Provider>
	);
}