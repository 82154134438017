import React, { useState } from 'react';
import { AppContextType } from './AppContext';
import { User } from './types';

export default function useApp(): AppContextType {
	const [currentUser, setCurrentUser] = useState<User | null>(null);

	return {
		state: {
			currentUser
		},
		actor: {
			setCurrentUser
		}
	}
}