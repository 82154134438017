import React, { SyntheticEvent, useState, useContext } from 'react';
import { useLocation } from 'wouter';
import { AuthProps } from './types';
import useAppContext from 'context/AppContext';
import { User } from 'context/types';
import { InputTypeOptions } from 'components/Form/types';
import Form from 'components/Form';
import Input from 'components/Form/Input';

interface FormFields {
	username: { value: string };
}


function Auth(): JSX.Element {
	const [location, setLocation] = useLocation();
	const { state: { currentUser }, actor: { setCurrentUser } } = useAppContext();

	function handleSubmit(event: SyntheticEvent) {
		event.preventDefault();
		const typedTarget = event.target as typeof event.target & FormFields;
		const user: User = {
			username: typedTarget.username.value
		}
		setCurrentUser(user);
		setLocation('/');
	}

	return(
		<div className='auth-form'>
			<Form submitHandler={handleSubmit}>
				<Input typeOfInput={InputTypeOptions.Text} inputPlaceholder='Username' inputName='username' />
			</Form>
		</div>
	);
}

export default Auth;