export interface FormProps {
	children: React.ReactNode;
	submitHandler: (e: React.SyntheticEvent) => void;
	submitPlaceholder?: string;
}

export interface InputProps {
	typeOfInput?: InputTypeOptions;
	inputPlaceholder?: string;
	inputName: string;
}

export enum InputTypeOptions {
	Password = 'password',
	Text = 'text',
	Number = 'number'
}