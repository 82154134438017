import React, { useState } from 'react';
import { Route } from 'wouter';
import Layout from 'templates/Layout';
import Auth from 'components/Auth';
import Calendar from 'components/Calendar';
import Day from 'components/Day';

function App(): JSX.Element {
    return (
        <Layout>
            <Route path='/' component={Calendar} />
            <Route path='/login' component={Auth} />
            <Route path='/day/:y/:m/:d'>{(p) => <Day day={p.d} month={p.m} year={p.y} />}</Route>
        </Layout>
    );
}

export default App;
